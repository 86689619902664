<template>
  <div class="epidemicPostDetail">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <div class="item" v-for="(item, index) in oData" :key="index">
        <el-form-item
          :label="item.problemName"
          :rules="[{ required: false, message: '', trigger: 'blur' }]"
        >
          <v-input
            disabled
            :width="width"
            v-model="item.problemValue"
          ></v-input>
        </el-form-item>
      </div>
    </form-panel>
  </div>
</template>

<script>
import { epidemicPostDetailUrl } from "./api.js";
import {} from "./map.js";

export default {
  name: "epidemicPostDetail",
  data() {
    return {
      width: 220,
      oData: [],
      submitConfig: {
        queryUrl: epidemicPostDetailUrl,
        submitUrl: "",
      },

      form: {
        id: null,
        nickName: "",
      },
    };
  },
  created() {
    const { id } = this.$route.query;
    // this.form.communityId = communityId;
    if (id !== undefined) {
      this.$nextTick(() => {
        this.$refs.formPanel.getData({
          recordId: id,
        });
      });
    }
    this.$setBreadList("防疫表单提交记录 / 查看");
  },
  methods: {
    update(data) {
      console.log(data);
      this.oData = data;
      //   Object.keys(this.form).forEach((key) => {
      //     this.form[key] = data[key] || this.form[key];
      //   });
    },
    submitBefore() {
      return true;
    },
    submitSuccess() {
      return true;
    },
  },
};
</script>

<style lang="less" scoped>
.epidemicPostDetail {
  box-sizing: border-box;
  height: 100%;
  padding: 20px;
}
</style>
